// React Router Dom
import { useHistory } from 'react-router-dom';

// React Redux
import { useDispatch } from 'react-redux';

// Redux Auth Actions
import { LoginWithSuccess, LogoutWithSuccess } from '../../redux/modules/Auth/AuthActions';

// Redux Auth Action Types
import { TLoginFormValues } from '../../redux/modules/Auth/AuthActionTypes';

// Services
import Api from '../Api/Api';

// Config
import environment from '../../configs/environment';

const AuthHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const login = async (formData: TLoginFormValues) => {
    const response = await Api.initialize().post('/login', formData);
    if (response.data.status === 'success') {
      dispatch(LoginWithSuccess(response.data.data));
    }
    return Promise.resolve(response.data);
  };

  const logout = async () => {
    const response = await Api.initialize().post('/logout');
    if (response.data.status === 'success') {
      dispatch(LogoutWithSuccess());
      history.push(environment.ROUTE_AUTH);
    }
    return Promise.resolve(response.data);
  };

  return {
    login,
    logout
  };
};

export default AuthHook;