import React from 'react';
import {
	Paper,
  Box,
} from "@material-ui/core";

// styles
import useStyles from '../Auth/styles/Login-jss';

// Child components
import LoginForm from './LoginForm';

const logo = `${process.env.PUBLIC_URL}/qualcomm_logo.png`;

const Login = () => {
	const classes = useStyles();
  return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="100vh"
		>
			<Paper elevation={3} className={classes.loginWrapper}>
				<Box  mb={4}>
					<img src={logo} className={classes.logo}  alt="Qualcomm" />
				</Box>
				<Box mt={2} mb={2}>
					<LoginForm />
				</Box>
			</Paper>
		</Box>
	);
}

export default Login;