import {
  TAuthDispatchTypes,
  LOGIN,
  LOGOUT
} from './AuthActionTypes';

// Utils
import { setCookie, deleteAllCookies } from '../../../utils/cookie';

// Config
import environment from '../../../configs/environment';

interface IAuthReducer {
  token: string | null;
};

const defaultState: IAuthReducer = {
  token: null
};

const authReducer = (state = defaultState, action: TAuthDispatchTypes) => {
  switch (action.type) {
    case LOGIN:
      setCookie('auth', action.payload.access_token, 15);
      return { ...state, token: action.payload.access_token };
    case LOGOUT:
      deleteAllCookies();
      window.location.replace(environment.ROUTE_AUTH);
      return { ...state, token: null };
    default:
      return state;
  }
};

export default authReducer;