import Api from '../Api/Api';

export type TTrackerReponseValues = {
  id: number;
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
};

export type TLocation = {
  Latitude: number;
  Longitude: number;
};

export type TTemperature = {
  Celsius: number;
  Fahrenheit: number;
};

export type TPressure = {
  Atmospheric: number
};

export type THumidity = {
  Percentage: number
};

export type TLog = {
  Location: TLocation,
  Temperature?: TTemperature,
  Pressure?: TPressure,
  Humidity?: THumidity
};
export type TSensorLogResponseValues = {
  id: number;
  log: TLog;
  created_at: string;
  updated_at: string;
};
type TPlatformResponseValues = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};
export type TEventsResponseValues = {
  id: number;
  key: string;
  description?: string;
  platform: TPlatformResponseValues;
  created_at: string;
  updated_at: string;
};

const Tracker = () => {
  const getTrackers = async () => {
    return await Api.initialize().get('/trackers');
  };

  const getSensorLogs = async (trackerId: string) => {
    return await Api.initialize().get(`/trackers/${trackerId}/sensor-logs`);
  };

  const getEvents = async (trackerId: string) => {
    return await Api.initialize().get(`/trackers/${trackerId}/events`);
  };

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  }

  /**
   * googleData = [
   *  [-38.788, 17.1223342],
   *  [-38.788, 17.1223342],
   * ];
  */
  const generateLogs = (googleData: any) => {
    const results: any = [];
    googleData.map((data: any) => {
      results.push({
        Temperature: {
          Celsius: getRandomArbitrary(0, 30),
          Fahrenheit: getRandomArbitrary(40, 85)
        },
        Location: {
          Latitude: data[0],
          Longitude: data[1]
        }
      });
    });
    return results;
  };

  return {
    getTrackers,
    getSensorLogs,
    getEvents,
    generateLogs
  };
};

export default Tracker;