import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

// MUI
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ButtonGroup,
  Button,
  MenuList,
  Typography,
  Box,
  Paper,
  Popper,
  Grow,
  ClickAwayListener
 } from '@material-ui/core';

// MUI Icons
import { ArrowDropDown } from '@material-ui/icons';

// Styles
import useStyles from '../styles/Dashboard-jss';

// Auth Hook
import AuthHook from '../../../services/Hooks/Auth';

// Child Components
import GoogleMap from './GoogleMap';

// Services
import TrackerHook, {
  TEventsResponseValues,
  TSensorLogResponseValues,
  TTrackerReponseValues
} from '../../../services/Hooks/Tracker';

type TFormDataValues = {
  increaseRate: number,
  deviceId: string,
  lengthOfMinute: number,
  lengthOfMinuteOpened: boolean,
  playing: boolean,
  inactivePresentation: boolean
};

const rangeValues: Array<number> = [1, 5, 10, 15];
const showMarkers: Array<number> = [1, 4, 7, 10];
let interval: ReturnType<typeof setInterval>;

const DashboardResults = () => {
  const classes = useStyles();
  // const [devices, setDevices] = useState<TTrackerReponseValues[]>([]);
  const [devices, setDevices] = useState<any>([]);
  // const [logs, setLogs] = useState<TSensorLogResponseValues[]>([]);
  const [logs, setLogs] = useState<any>([]);
  // const [events, setEvents] = useState<TEventsResponseValues[]>([]);
  const [events, setEvents] = useState<any>([]);
  const [formData, setFormData] = useState<TFormDataValues>({
    increaseRate: 1,
    deviceId: '',
    lengthOfMinute: 1,
    lengthOfMinuteOpened: false,
    playing: false,
    inactivePresentation: true 
  });
  const [eventsPresentation, setEventsPresentation] = useState<any>([]);
  const [markersPresentation, setMarkersPresentation] = useState<any>([]);
  const authHook = AuthHook();
  const trackerHook = TrackerHook();

  const anchorRef = useRef<HTMLDivElement>(null);

  const getLogsAndEvens = async (deviceId: string) => {
    const logsResp = await trackerHook.getSensorLogs(deviceId);
    const eventsResp = await trackerHook.getEvents(deviceId);
    let logsLoaded = false;
    let eventsLoaded = false;
    if (logsResp.data !== undefined && logsResp.data.status === 'success') {
      if (logsResp.data.data.length > 0) {
        setMarkersPresentation([logsResp.data.data[0]]);
        logsLoaded = true;
      }
      setLogs(logsResp.data.data);
    }

    if (eventsResp.data !== undefined && eventsResp.data.status === 'success') {
      if (eventsResp.data.data.length > 0) {
        setEventsPresentation([eventsResp.data.data[0]]);
        eventsLoaded = true;
      }
      setEvents(eventsResp.data.data);
    }
    if (logsLoaded && eventsLoaded) {
      setFormData({
        ...formData,
        deviceId: deviceId,
        increaseRate: isStoryOne(logsResp.data.data) ? 5 : 1,
        inactivePresentation: false
      });
    }
    // trackerHook.getSensorLogs(deviceId).then((res: any) => {
    //   if (res.data !== undefined && res.data.status === 'success') {
    //     if (res.data.data.length > 0) {
    //       setMarkersPresentation([res.data.data[0]]);
    //       setFormData({
    //         ...formData,
    //         deviceId: deviceId,
    //         inactivePresentation: false,
    //         increaseRate: isStoryOne(res.data.data) ? 5 : 1
    //       });
    //     }
    //     setLogs(res.data.data);
    //   }
    // });
    // trackerHook.getEvents(deviceId).then((res: any) => {
    //   if (res.data !== undefined && res.data.status === 'success') {
    //     if (res.data.data.length > 0) {
    //       setEventsPresentation([res.data.data[0]]);
    //     }
    //     setEvents(res.data.data);
    //   }
    // });
  };
  useEffect(() => {
    trackerHook.getTrackers().then((response) => {
      if (response.data !== undefined && response.data.status === 'success') {
        setDevices(response.data.data);
        if (response.data.data.length > 0) {
          setFormData({ ...formData, deviceId: response.data.data[0].uuid, inactivePresentation: true });
          getLogsAndEvens(response.data.data[0].uuid);
        }
      }
    });
    return () => {
      clearInterval(interval);
    };
  }, []);

  const resetPresentation = () => {
    setMarkersPresentation(logs.length > 0 ? [logs[0]] : []);
    setEventsPresentation(events.length > 0 ? [events[0]] : []);
  };

  // const generateDataAtPresentation = () => {
  //   let step = 0;
  //   const logsData = [...logs];
  //   const eventsData = [...events];
  //   if (!step) {
  //     logsData.shift();
  //     eventsData.shift();
  //     step += 1;
  //   }
  //   interval = setInterval(() => {
  //     const logElement = logsData.shift();
  //     const eventElement = eventsData.shift();
  //     setMarkersPresentation((prevState: TSensorLogResponseValues[]) => prevState.concat(logElement));
  //     setEventsPresentation((prevState: TEventsResponseValues[]) => prevState.concat(eventElement));

  //     if (!logsData.length) {
  //       clearInterval(interval);
  //     };
  //   }, (formData.increaseRate * formData.lengthOfMinute) * 1000);
  // };
  const generateDataAtPresentation = (logsData: any = [], eventsData: any = [], delay: number, stopped = false) => {
    interval = setInterval(() => {
      const logElement = logsData.shift();
      setMarkersPresentation((prevState: TSensorLogResponseValues[]) => prevState.concat(logElement));
      if (eventsData.length) {
        const eventElement = eventsData.shift();
        setEventsPresentation((prevState: TEventsResponseValues[]) => prevState.concat(eventElement));
      }
      if (!logsData.length) {
        clearInterval(interval);
        if (isStoryOne([...logs]) && !stopped) {
          generateDataAtPresentation(
            logs.slice(3),
            events.slice(3) || [],
            rangeValues[rangeValues.indexOf(formData.increaseRate) - 1] * formData.lengthOfMinute,
            true);
        }
      };
    }, delay * 1000);
  };

  const handleChange = (ev: any) => {
    const newFormData = { ...formData, [ev.target.name]: ev.target.value };
    if (ev.target.name === 'deviceId') {
      getLogsAndEvens(ev.target.value);
      newFormData.inactivePresentation = true;
    }
    if (ev.target.name === 'lengthOfMinute') {
      newFormData.lengthOfMinuteOpened = !formData.lengthOfMinuteOpened;
      newFormData.playing = true;
      handleStartPresentation(newFormData.increaseRate * ev.target.value);
    }
    setFormData(newFormData);
  };

  const handleStartPresentation = (delay: number) => {
    setFormData({ ...formData, playing: !formData.playing });
    if (!formData.playing) {
      let logsData = logs.slice(1);
      let eventsData = events.slice(1);
      if (isStoryOne(logs)) {
        logsData = logs.slice(1,3);
        eventsData = events.slice(1,3);
      }
      generateDataAtPresentation(logsData, eventsData, delay);
    } else {
      resetPresentation();
      setFormData({ ...formData, playing: false, inactivePresentation: false });
      clearInterval(interval);
    }
  };

  const handleCloseSimulateList = (event: React.MouseEvent<Document, MouseEvent> | null) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
  };

  const handleToggleSimulateList = (event?: React.MouseEvent<Document, MouseEvent>) => {
    handleCloseSimulateList(event || null);
    setFormData({ ...formData, lengthOfMinuteOpened: !formData.lengthOfMinuteOpened });
  };

  // just for demo
  const isStoryOne = (logsData: TSensorLogResponseValues[]) => logsData.length === 7;

  const renderEvents = () => {
    if (!eventsPresentation.length) {
      return <Paper className={classes.sensorLogElement}> <Typography variant="subtitle1">No events yet </Typography></Paper>;
    }
    return eventsPresentation.map((item: TEventsResponseValues, i:number) => {
      let eventDescription = item.description || item.key;
      if (item.key === 'increase_rate' && eventDescription.indexOf('{from}') !== -1) {
        const prevIncreaseRate = rangeValues[rangeValues.indexOf(formData.increaseRate) - 1];
        eventDescription = eventDescription.replace('{from}', `${formData.increaseRate}`)
          .replace('{to}', `${prevIncreaseRate}`)
          .replace('{from_unit}', 'minutes')
          .replace('{to_unit}', `minute${prevIncreaseRate > 1 ? 's' : ''}`);
      }
      if (i > 0 && (
        (!isStoryOne(logs) && eventsPresentation.length < events.length && showMarkers.indexOf(i + 1) === -1)
        || i === eventsPresentation.length - 1
      )) return null;
      return (
        <Paper key={`events-${item.id}`} className={classes.sensorLogElement}>
          <Typography variant="subtitle1">{eventDescription}</Typography>
        </Paper>
      );
    });
  };
  const renderLogText = (log: TSensorLogResponseValues['log']) => {
    let text = '';
    if (log.Temperature !== undefined) {
      text += ` Temp ${log.Temperature.Fahrenheit} F`;
    }
    if (log.Pressure !== undefined) {
      text += ` Pressure Atmospheric ${log.Pressure.Atmospheric.toFixed(2)} `;
    }
    if (log.Humidity !== undefined) {
      text += ` Humidity ${log.Humidity.Percentage.toFixed(2)}% `;
    }
    return text;
  };
  const renderLogs = () => {
    if (!markersPresentation.length) {
      return <Paper elevation={3} className={classes.sensorLogElement}><Typography variant="subtitle1">No logs yet </Typography></Paper>;
    }
    return markersPresentation.map((marker: TSensorLogResponseValues, i:number) => {
      if (i > 0 && (
        (!isStoryOne(logs) && markersPresentation.length < logs.length && showMarkers.indexOf(i + 1) === -1)
        || i === markersPresentation.length - 1
      )) return null;
      return (
        <Paper key={`sensor-logs-${marker.id}`} elevation={3} className={classes.sensorLogElement}>
          <Typography variant="subtitle1">{moment(marker.created_at).format('MM/DD/YYYY')}</Typography>
          <Typography variant="subtitle1">&nbsp;{moment(marker.created_at).format('hh:mm A')}</Typography>
          <Typography variant="subtitle1">{renderLogText(marker.log)}</Typography>
        </Paper>
      );
    });
  };

  const renderForm = () => (
    <React.Fragment>
      <Grid container spacing={3} className={classes.resultsContainer}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel id="deviceId" className={classes.formControlLabel}>Device ID </InputLabel>
              <Select
                name="deviceId"
                labelId="deviceId"
                id="deviceId"
                value={formData.deviceId}
                onChange={(ev) => handleChange(ev)}
                fullWidth
                variant="filled"
                disabled={formData.playing}
              >
                <MenuItem selected disabled>Select a device</MenuItem>
                {devices.length > 0 && devices.map((device: TTrackerReponseValues) => (
                  <MenuItem key={device.uuid} value={device.uuid}>{device.uuid}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
              <InputLabel id="increaseRate" className={classes.formControlLabel}>Reporting Interval</InputLabel>
              <Select
                name="increaseRate"
                labelId="increaseRate"
                id="increaseRate"
                value={formData.increaseRate}
                onChange={(ev) => handleChange(ev)}
                fullWidth
                variant="filled"
                disabled={formData.playing}
              >
                {rangeValues.map((val) => (
                  <MenuItem disabled={isStoryOne(logs) && val === 1} key={`rangevalues-${val}`} value={val}>{`${val} minute${val > 1 ? 's' : ''}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.selectContainer}>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Button disabled={formData.inactivePresentation} onClick={() => handleStartPresentation()} variant="contained" color={formData.playing ? 'secondary' : 'primary'} fullWidth size="large"  className={classes.btnPlay}>
            {formData.playing ? 'STOP' : 'PLAY'}
          </Button>
          <FormControl fullWidth>
            <InputLabel id="presentation" className={classes.formControlLabel}>Length of a minute </InputLabel>
            <Select
              name="lengthOfMinute"
              labelId="length"
              id="length"
              value={formData.lengthOfMinute}
              onChange={(ev) => handleChange(ev)}
              fullWidth
              variant="filled"
              disabled={formData.playing}
            >
              {rangeValues.map((val) => (
                <MenuItem key={`rangevalues-seconds-${val}`} value={val}>{`${val} second${val > 1 ? 's' : ''}`}</MenuItem>
              ))}
              <MenuItem value={60}> 60 seconds</MenuItem>
            </Select>
          </FormControl> */}

          <ButtonGroup className={classes.buttonGroup} variant="contained" color="primary" ref={anchorRef} aria-label="split button">
            <Button disabled={formData.inactivePresentation} onClick={() => handleStartPresentation(formData.increaseRate * formData.lengthOfMinute)} variant="contained" color={formData.playing ? 'secondary' : 'primary'} fullWidth size="large"  className={classes.btnPlay}>
              {formData.playing ? 'STOP' : 'SIMULATE'}
            </Button>
            <Button
              color="primary"
              size="small"
              aria-controls={formData.lengthOfMinuteOpened ? 'split-button-menu' : undefined}
              aria-expanded={formData.lengthOfMinuteOpened ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              disabled={formData.playing || formData.inactivePresentation}
              onClick={(ev) => handleToggleSimulateList()}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <Popper open={formData.lengthOfMinuteOpened} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={(ev) => handleToggleSimulateList(ev)}>
                    <MenuList id="split-button-menu">
                      {rangeValues.map((val) => (
                        <MenuItem
                          key={`rangevalues-seconds-${val}`}
                          value={val}
                          onClick={() => handleChange({
                            target: {
                              name: 'lengthOfMinute',
                              value: val
                            }
                          })}
                          selected={val === formData.lengthOfMinute}
                        >
                          {`${val} second${val > 1 ? 's' : ''}`}
                        </MenuItem>
                      ))}
                      <MenuItem
                        key={`rangevalues-seconds-60`}
                        value={60}
                        onClick={() => handleChange({
                          target: {
                            name: 'lengthOfMinute',
                            value: 60
                          }
                        })}
                        selected={formData.lengthOfMinute === 60}
                      >
                        60 seconds
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.sensorLogContainer}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>Sensor Log</Typography>
          <Box className={classes.sensorLogResults}>
            {renderLogs()}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.sensorLogContainer}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title}>Event Queue</Typography>
          <Box className={classes.eventQueueResults}>
            {renderEvents()}
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.sensorLogContainer}>
        <Grid item xs={12}>
          <Button onClick={() => authHook.logout()} variant="contained" size="large">Log Out</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={7}>
        <GoogleMap showMarkers={showMarkers} partialMarkers={!isStoryOne(logs)} markers={markersPresentation || []} />
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        {renderForm()}
      </Grid>
    </React.Fragment>
  );
};
export default DashboardResults;