export const LOGIN = 'src/redux/modules/Auth/AuthActionTypes/LOGIN';
export const LOGOUT = 'src/redux/modules/Auth/AuthActionTypes/LOGOUT';

export type TLoginFormValues = {
  email: string;
  password: string;
};

export type TLoginResponseValues = {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

interface ILoginWithSuccess { type: typeof LOGIN, payload: TLoginResponseValues };
interface ILogoutWithSuccess { type: typeof LOGOUT };

export type TAuthDispatchTypes = ILoginWithSuccess
| ILogoutWithSuccess;