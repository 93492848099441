import {
  TAuthDispatchTypes,
  TLoginResponseValues,

  LOGIN,
  LOGOUT
} from "./AuthActionTypes";

export const LoginWithSuccess = (data: TLoginResponseValues): TAuthDispatchTypes => {
  return ({
    type: LOGIN,
    payload: data
  });
};

export const LogoutWithSuccess = (): TAuthDispatchTypes => {
  return ({
    type: LOGOUT
  });
};