import React from 'react';
import { Switch } from 'react-router-dom';

// Components
import { PublicRoute, PrivateRoute } from './components/Routes';

// Pages
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard';

function App() {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Dashboard} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PublicRoute path="/login" exact component={Login} />
    </Switch>
  );
}

export default App;

