
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Config
import environment from '../../configs/environment';

// Utils
import { getCookie } from '../../utils/cookie';

const PublicRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) => (
    !getCookie('auth')
      ? React.createElement(component, props)
      : <Redirect to={{ pathname: environment.ROUTE_HOME }}/>
  );
  return <Route {...rest} render={routeComponent}/>;
};

export default PublicRoute;