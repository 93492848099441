import React, { useState } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow
} from "react-google-maps";

// Services
import { TSensorLogResponseValues, TLocation } from '../../../services/Hooks/Tracker';

// Config
import environment from '../../../configs/environment';

// Styles
import useStyles from './../styles/Dashboard-jss';
import { Typography } from '@material-ui/core';

const defaultLocation: TLocation = {
  Latitude: 40.712122,
  Longitude: -74.005600
};

const MarkerAnimation = (props: any) => {
  const [toggleMarker, setTooggleMarker] = useState<number | null>(null);
  return (
    <GoogleMap
      defaultZoom={10}
      center={
        props.markers.length > 0
          ? { lat: props.markers[0].log.Location.Latitude, lng: props.markers[0].log.Location.Longitude }
          : { lat: defaultLocation.Latitude, lng: defaultLocation.Longitude }
        }
      zoom={18}
    >
      {props.markers.length > 0
        ? props.markers.map((marker: TSensorLogResponseValues, i: number) => {
          if ((props.partialMarkers && props.markers.length < 11 && props.showMarkers.indexOf(i + 1) === -1) || i === 10) return null;
          return (
            <Marker
              onClick={() => setTooggleMarker(marker.id)}
              key={marker.id}
              animation={2}
              position={
                marker.log.Location !== undefined
                  ? { lat: marker.log.Location.Latitude, lng: marker.log.Location.Longitude }
                  : { lat: defaultLocation.Latitude, lng: defaultLocation.Longitude }
                }
            >
              {toggleMarker === marker.id && (
                <InfoWindow onCloseClick={() => setTooggleMarker(null)}>
                  <React.Fragment>
                    {marker.log.Temperature !== undefined && <Typography>Temperature: {marker.log.Temperature.Fahrenheit.toFixed(2)} F</Typography>}
                    {marker.log.Pressure !== undefined && <Typography>Pressure Atmospheric: {marker.log.Pressure.Atmospheric.toFixed(2)}</Typography>}
                    {marker.log.Humidity !== undefined && <Typography>Humidity: {marker.log.Humidity.Percentage.toFixed(2)}%</Typography>}
                    <Typography>Latitude: {marker.log.Location.Latitude}</Typography>
                    <Typography>Longitude: {marker.log.Location.Longitude}</Typography>
                  </React.Fragment>
                </InfoWindow>
              )}
            </Marker>
          );
        })
        : (
          <React.Fragment>
            <Marker
              animation={2}
              position={{
                lat: defaultLocation.Latitude,
                lng: defaultLocation.Longitude,
              }}
            />
          </React.Fragment>
          )
    }
    </GoogleMap>
  );
};

interface IProps {
  markers: TSensorLogResponseValues[];
  partialMarkers: boolean;
  showMarkers: Array<number>;
};

const WrappedGoogleMap = withScriptjs(withGoogleMap(MarkerAnimation));

const GoogleMapComponent = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.mapContainer}>
      <WrappedGoogleMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        markers={props.markers}
        partialMarkers={props.partialMarkers}
        showMarkers={props.showMarkers}
      />
    </div>
  );
};
export default GoogleMapComponent;
