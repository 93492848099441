import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

// MUI
import {
  Grid,
  TextField,
  Button
} from "@material-ui/core";

// Redux Auth ActionTypes
import { TLoginFormValues } from '../../redux/modules/Auth/AuthActionTypes';

// Auth Hook
import AuthHook from '../../services/Hooks/Auth';

// config
import environment from '../../configs/environment';

// styles
import useStyles from '../Auth/styles/Login-jss';

const LogInValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(2, 'Password too Short!')
    .max(50, 'Password too Long!')
    .required('Required'),
  });

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const authHook = AuthHook();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LogInValidationSchema,
    onSubmit: async (values: TLoginFormValues, { setSubmitting }) => {
      setSubmitting(true);
      const response = await authHook.login(values);
      if (response) {
        if (response.status === 'success') {
          history.push(environment.ROUTE_HOME);
        }
        setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            size="medium"
            name="email"
            label="Email"
            variant="outlined"
            placeholder="username@qualcomm.com"
            color="primary"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            type="password"
            size="medium"
            color="primary"
            name="password"
            variant="outlined"
            placeholder="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12 } md={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          className={classes.submitBtn}
          disabled={formik.isSubmitting}
        >
          LOGIN
        </Button>
      </Grid>
    </form>
  );
};

export default LoginForm;