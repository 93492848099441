import React from 'react';
import { Grid } from '@material-ui/core';
import DashboardResults from './components/DashboardResults';
import useStyles from './styles/Dashboard-jss';

const Dashboard = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.dashboardParent}>
        <DashboardResults />
      </Grid>
    </React.Fragment>
  );
};
export default Dashboard;