
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Config
import environment from '../../configs/environment';

// Utils
import { getCookie } from '../../utils/cookie';

const PrivateRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) => (
    !getCookie('auth')
      ? <Redirect to={environment.ROUTE_AUTH} />
      : React.createElement(component, props)
  );
  return <Route {...rest} render={routeComponent}/>;
};

export default PrivateRoute;