import Swal from 'sweetalert2';

const alert = (error: string) => (
  Swal.fire({
    text: error,
    toast: true,
    position: 'top-right',
    closeButtonHtml: '&times;',
    showCloseButton: true,
    showConfirmButton: false
  })
);

export default alert;