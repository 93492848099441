import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    loginWrapper: {
      padding: 50,
    },
		submitBtn: {
			marginTop: 30,
			paddingHorizontal: 20
		},
		logo: {
			width: 150,
			margin: '0 auto',
			display: 'block'
		}
}));

export default useStyles;